import AppConfig from '@/configs/app.config.js'

import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')

Vue.use(Router)

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/manage-lotto/daily',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: '/manage-lotto',
          component: { render (c) { return c('router-view') } },
          children: [
            {
              path: 'daily',
              name: 'MarketDashboard',
              component: () => import('@/views/manage-lotto/Dashboard')
            },
            {
              path: 'daily/:lottoDate',
              name: 'MarketDate',
              component: () => import('@/views/manage-lotto/Dashboard')
            },
            {
              path: 'wait-result',
              name: 'WaitResults',
              component: () => import('@/views/manage-lotto/WaitResults')
            },
            {
              path: 'setting',
              name: 'LottoSettings',
              component: () => import('@/views/manage-lotto/Settings')
            },
            {
              path: 'award',
              name: 'AwardNumbers',
              component: () => import('@/views/manage-lotto/AwardNumbers')
            },
            {
              path: 'won-ticket',
              name: 'WonTickets',
              component: () => import('@/views/manage-lotto/WonTickets')
            },
            {
              path: 'pay',
              name: 'PayWonTickets',
              component: () => import('@/views/manage-lotto/PayWonTickets')
            }
          ]
        }, {
          path: '/ticket',
          component: { render (c) { return c('router-view') } },
          children: [{
            path: 'last500',
            name: 'Last500Tickets',
            component: () => import('@/views/ticket/Last500Tickets')
          }, {
            path: 'search',
            name: 'SearchTickets',
            component: () => import('@/views/ticket/SearchTickets')
          }, {
            path: 'search-number',
            name: 'SearchNumbers',
            component: () => import('@/views/ticket/SearchNumbers')
          }, {
            path: 'refund-ticket',
            name: 'RefundTicket',
            component: () => import('@/views/ticket/RefundTicket')
          }, {
            path: 'queue',
            name: 'TicketQueues',
            component: () => import('@/views/ticket/TicketQueues')
          }]
        }, {
          path: '/setting',
          component: { render (c) { return c('router-view') } },
          children: [{
            path: 'bet',
            name: 'SettingBet',
            component: () => import('@/views/setting/SettingBet')
          }, {
            path: 'data',
            name: 'RoundData',
            component: () => import('@/views/setting/RoundData')
          }]
        }, {
          path: '/news',
          component: { render (c) { return c('router-view') } },
          children: [{
            path: 'express',
            name: 'ExpressNews',
            component: () => import('@/views/news/ExpressNews')
          }, {
            path: 'main',
            name: 'MainNews',
            component: () => import('@/views/news/MainNews')
          }]
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/404',
      name: 'Page404',
      component: Page404
    },
    {
      path: '/500',
      name: 'Page500',
      component: Page500
    }
  ]
}

router.beforeEach((to, from, next) => {
  const publicPages = ['Login'];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem(AppConfig.key.LoggedIn);

  console.log('router.beforeEach')

  if (authRequired && !loggedIn) {
    next({name: 'Login'});
  } else {
    next();
  }
});

export default router
