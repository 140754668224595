export const marketStatus = [
  {
    code: 'Open',
    icon: 'fas fa-circle',
    textColor: 'text-success',
    borderClass: 'success',
    text: 'เปิดรับแทง'
  },
  {
    code: 'Close',
    icon: 'fas fa-ban',
    textColor: 'text-secondary',
    borderClass: 'secondary',
    text: 'ปิดแทง'
  },
  {
    code: 'Delete',
    icon: 'fas fa-trash-alt',
    textColor: 'text-danger',
    borderClass: 'danger',
    text: 'ลบแล้ว'
  }
]

export const daysOfWeek = [
  {
    code: 'Mon',
    text: 'จันทร์'
  },
  {
    code: 'Tue',
    text: 'อังคาร'
  },
  {
    code: 'Wed',
    text: 'พุธ',
  },
  {
    code: 'Thu',
    text: 'พฤหัสบดี'
  },
  {
    code: 'Fri',
    text: 'ศุกร์'
  },
  {
    code: 'Sat',
    text: 'เสาร์'
  },
  {
    code: 'Sun',
    text: 'อาทิตย์'
  }
]

export const marketOpenBets = [
  {
    code: 'threeNumberTop',
    text: '3 ตัวบน'
  },
  // {
  //   code: 'threeNumberBottom',
  //   text: '3 ตัวล่าง'
  // },
  {
    code: 'threeNumberTode',
    text: '3 ตัวโต๊ด'
  },
  {
    code: 'twoNumberTop',
    text: '2 ตัวบน'
  },
  {
    code: 'twoNumberBottom',
    text: '2 ตัวล่าง'
  },
  {
    code: 'runTop',
    text: 'วิ่งบน'
  },
  {
    code: 'runBottom',
    text: 'วิ่งล่าง'
  }
]

export const openBetLabels = {
  threeNumberTop: '3 ตัวบน',
  threeNumberTode: '3 ตัวโต๊ด',
  twoNumberTop: '2 ตัวบน',
  twoNumberBottom: '2 ตัวล่าง',
  runTop: 'วิ่งบน',
  runBottom: 'วิ่งล่าง',
  lottoset: 'เลขชุด',
  fourNumber: '4 ตัวตรง',
  fourNumberTode: '4 ตัวโต๊ด',
  threeNumber: '3 ตัวตรง',
  threeNumberTode: '3 ตัวโต๊ด',
  twoNumberPrefix: '2 ตัวหน้า',
  twoNumberSuffix: '2 ตัวหลัง'
}
